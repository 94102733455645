import jsonFromDocument from "cerulean/src/JsonFromDocument";
import { StaffApiSchema } from "../api_utils";

type User = StaffApiSchema.components["schemas"]["Profile"];

export type Roles = {
  narmi: boolean;
  superUser: boolean;
};

const isFeatureEnabled = (name: string, value?: unknown): boolean => {
  const features = Object.entries(
    jsonFromDocument("institution_features", "null") ?? jsonFromDocument("features")
  ).filter(([, feature]) => feature);
  return features.some(([featureName, featureValue]: [string, unknown]) => {
    if (featureName === name) {
      if (!value) {
        return true;
      }
      if (typeof featureValue === "string") {
        return featureValue === value;
      }
      if (Array.isArray(featureValue)) {
        return featureValue.includes(value);
      }
    }
  });
};

export function useUserConfiguration(): {
  isFeatureEnabled: (name: string, value?: unknown) => boolean;
  features: string[];
  permissions: string[];
  currentUser: User;
  roles: Roles;
} {
  // this might smell like a useless hook, but we might be moving to launchdarkly
  // and having a hook will future-proof us a little bit.
  const currentUser = jsonFromDocument("current_user");
  const features = Object.entries(
    jsonFromDocument("institution_features", "null") ?? jsonFromDocument("features")
  )
    .filter(([, value]) => value)
    .map(([key]) => key);

  return {
    isFeatureEnabled,
    features,
    permissions: jsonFromDocument("user_permissions"),
    currentUser,
    roles: {
      narmi: currentUser?.is_narmi || false,
      superUser: currentUser?.is_superuser || false,
    },
  };
}

export type UserConfigurationType = ReturnType<typeof useUserConfiguration>;
